<script>
import ContactUs from "../components/contactus/ContactUs.vue";

export default {
  name: "Contact Us",
  components: {
    ContactUs,
  },
};
</script>

<template>
  <div class="container mx-auto">
    <ContactUs />
  </div>
</template>
