<script>
export default {
  props: ["conditionsWeTreatPage"],
};
</script>

<template>
  <div
    class="mt-10 sm:mt-10 mb-10  cursor-pointer bg-secondary-light dark:bg-ternary-dark"
  >
    <div class="flex flex-col rounded shadow-sm md:justify-center lg:flex-row">
      <div class="relative lg:w-auto">
        <img
          :src="conditionsWeTreatPage.img"
          class="object-cover w-96 h-80 lg:h-full rounded-xl shadow-lg hover:shadow-xl"
        />
      </div>
      <div class="w-full ml-auto mr-auto px-4 py-5">
        <h5
          class="mb-4 text-3xl sm:text-4xl font-general-semibold text-ternary-dark dark:text-ternary-light font-semibold"
        >
          {{ conditionsWeTreatPage.title }}
        </h5>
        <p class="font-general-regular text-gray-400 mb-4 text-justify">
          {{ conditionsWeTreatPage.shortContent }}
        </p>
        <p class="font-general-regular text-gray-400 text-justify">
          {{ conditionsWeTreatPage.longContent }}
        </p>
      </div>
    </div>
  </div>
</template>
