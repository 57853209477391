<script>
import ConditionsWeTreatPage from "../components/conditionswetreat/ConditionsWeTreatPageGrid.vue";
export default {
  name: "ConditionsWeTreat",
  components: { ConditionsWeTreatPage },
};
</script>

<template>
  <div class="container mx-auto">
    <ConditionsWeTreatPage />
  </div>
</template>
