<script>
import ConditionsWeTreatPageSingle from "../conditionswetreat/ConditionsWeTreatPageSingle.vue";
import conditionsWeTreatPage from "../../content/conditionswetreat.json";

export default {
  components: { ConditionsWeTreatPageSingle },
  data: () => {
    return {
      conditionsWeTreatPage,
      conditionsWeTreatPageHeading: "Conditions We Treat Section",
    };
  },
  computed: {
    filteredConditionsWeTreatPage() {
      return this.conditionsWeTreatPage;
    },
  },
};
</script>

<template>
  <section class="pt-10 sm:pt-14">
    <div class="text-center">
      <p
        class="font-general-semibold text-5xl sm:text-5xl mb-2 text-ternary-dark dark:text-ternary-light"
      >
        {{ conditionsWeTreatPageHeading }}
      </p>
    </div>

    <div>
      <ConditionsWeTreatPageSingle
        v-for="conditionsWeTreatPage in filteredConditionsWeTreatPage"
        :key="conditionsWeTreatPage.id"
        :conditionsWeTreatPage="conditionsWeTreatPage"
      />
    </div>
  </section>
</template>
